let defaultProjectState = {
	prismaOptions: [{ title: 'Database and Register Search', description: "Prisma Diagram Specialised for search studies only", officialTitle: "Flow diagram for new systematic reviews which included searches of databases and registers only", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" },
	{ title: 'Database, Registers and other Search', description: "Prisma Diagram Specialised for search studies from databases, Registers and other methods", officialTitle: "Flow diagram for new systematic reviews which included searches of databases, registers and other sources", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" },
	{ title: 'Updated Database and Register Search', description: "Updated Prisma Diagram Specialised for search studies only", officialTitle: "Flow diagram for updated systematic reviews which included searches of databases and registers only", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" },
	{ title: 'Updated Database, Registers and other Search', description: "Updated Prisma Diagram Specialised for search studies from databases, Registers and other methods", officialTitle: "Flow diagram for updated systematic reviews which included searches of databases, registers and other sources", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" }],
	
	// Database and Register Search
	Diagram1: "",
	prismatableDatabaseSearch: {
		headers: ["", "Identification of studies via databases and registers", "Identification of studies via databases and registers"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Records identified from*: Databases (n=xxx)
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening:
Duplicate records removed (n=xxx)
Records marked as ineligible by automation tools (n=xxx)
Records removed for other reasons (n=xxx)`, addClass: []
			}],
			[{ label: "Screening", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports excluded: Reason1 (n=xxx) Reason2 (n=xxx) Reason3 (n=xxx) etc.", addClass: [] }],
			[{ label: "Included", addClass: [] },
			{ label: "Studies included in review(n=xxx). Reports of included studies (n=xxx)", addClass: [] },
			{ label: "", addClass: [] }],
		]
	},
	formatedDatabaseSearch: {
		headers: ["", "Identification of studies via databases and registers", "Identification of studies via databases and registers"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Records identified from*: Databases (n=xxx) 
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening:
Duplicate records removed (n=xxx)
Records marked as ineligible by automation tools (n=xxx)
Records removed for other reasons (n=xxx)`, addClass: []
			}],
			[{ label: "Screening", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports excluded: Reason1 (n=xxx) Reason2 (n=xxx) Reason3 (n=xxx) etc.", addClass: [] }],
			[{ label: "Included", addClass: [] },
			{ label: "Studies included in review(n=xxx). Reports of included studies (n=xxx)", addClass: [] },
			{ label: "", addClass: [] }],
		]
	},
	// Database, Registers and other Search
	Diagram2: "",
	prismatableOtherSearch: {
		headers: ["", "Identification of studies via databases and registers", "Identification of studies via databases and registers", "Identification of studies via other methods", "Identification of studies via other methods"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Records identified from*: 
Databases (n=xxx), 
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening: 
Duplicate records removed (n=xxx) 
Records marked as ineligible 
by automation tools (n=xxx)
Records removed for other reasons (n=xxx)`, addClass: []
			},
			{
				label: `Records identified from: 
Websites (n=xxx)
Organisations (n=xxx)
Citation searching (n=xxx) etc.`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] }],
			[{ label: "Screening", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.
Records identified from:`, addClass: []
			},
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-sub-left"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.` , addClass: []
			}],
			[{ label: "Included", addClass: [] },
			{
				label: `Studies included in review (n=xxx)
Reports of included studies (n=xxx)`, addClass: []
			},
			{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
		]
	},
	formatedOtherSearch: {
		headers: ["", "Identification of studies via databases and registers", "Identification of studies via databases and registers", "Identification of studies via other methods", "Identification of studies via other methods"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Records identified from*: 
Databases (n=xxx), 
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening: 
Duplicate records removed (n=xxx) 
Records marked as ineligible 
by automation tools (n=xxx)
Records removed for other reasons (n=xxx)`, addClass: []
			},
			{
				label: `Records identified from: 
Websites (n=xxx)
Organisations (n=xxx)
Citation searching (n=xxx) etc.`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] }],
			[{ label: "Screening", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.
Records identified from:`, addClass: []
			},
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-sub-left"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.` , addClass: []
			}],
			[{ label: "Included", addClass: [] },
			{
				label: `Studies included in review (n=xxx)
Reports of included studies (n=xxx)`, addClass: []
			},
			{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
		]
	},
	// Updated Database and Register Search
	Diagram3: "",
	prismatableUpdateDatabaseSearch: {
		headers: ["", "Previous studies", "Identification of new studies via databases and registers", "Identification of new studies via databases and registers"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Studies included in 
previous version of 
review (n=xxx)
Reports of studies 
included in previous 
version of review (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records identified from*:
Databases (n=xxx)
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening:
Duplicate records removed (n=xxx)
Records marked as ineligible 
by automation tools (n=xxx)
Records removed for other 
reasons (n=xxx)`, addClass: []
			}],
			[{ label: "Screening", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.`, addClass: []
			}],
			[{ label: "Included", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `New studies included in review (n=xxx)
Reports of new included studies (n=xxx)`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `Total studies included in review (n=xxx)
Reports of total included studies (n=xxx)`, addClass: []
			},
			{ label: "", addClass: [] }]
		]
	},
	formatedUpdateDatabaseSearch: {
		headers: ["", "Previous studies", "Identification of new studies via databases and registers", "Identification of new studies via databases and registers"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Studies included in 
previous version of 
review (n=xxx)
Reports of studies 
included in previous 
version of review (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records identified from*:
Databases (n=xxx)
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening:
Duplicate records removed (n=xxx)
Records marked as ineligible 
by automation tools (n=xxx)
Records removed for other 
reasons (n=xxx)`, addClass: []
			}],
			[{ label: "Screening", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.`, addClass: []
			}],
			[{ label: "Included", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `New studies included in review (n=xxx)
Reports of new included studies (n=xxx)`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `Total studies included in review (n=xxx)
Reports of total included studies (n=xxx)`, addClass: []
			},
			{ label: "", addClass: [] }]
		]
	},
	// Updated Database, Registers and other Search
	Diagram4: "",
	prismatableUpdateOtherSearch: {
		headers: ["", "Previous studies", "Identification of new studies via databases and registers", "Identification of new studies via databases and registers", "Identification of new studies via other methods", "Identification of new studies via other methods"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Studies included in previous version of review (n=xxx)
Reports of studies included in previous version of review (n=xxx)`, addClass: ["arrow-down"]
			},
			{
				label: `Records identified from*:
Databases (n=xxx)
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening:
Duplicate records removed  (n=xxx)
Records marked as ineligible by automation tools (n=xxx)
Records removed for other reasons (n=xxx)`, addClass: []
			},
			{
				label: `Records identified from:
Websites (n=xxx)
Organisations (n=xxx)
Citation searching (n=xxx) etc.`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] }],
			[{ label: "Screening", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.`, addClass: []
			},
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-sub-left"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.`, addClass: []
			}],
			[{ label: "Included", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `New studies included in review (n=xxx)
Reports of new included studies (n=xxx)`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
			[{ label: "Included", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `Total studies included in review (n=xxx)
Reports of total included studies (n=xxx)`, addClass: []
			},
			{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }]
		]
	},
	formatedUpdateOtherSearch: {
		headers: ["", "Previous studies", "Identification of new studies via databases and registers", "Identification of new studies via databases and registers", "Identification of new studies via other methods", "Identification of new studies via other methods"],
		rows: [
			[{ label: "Identification", addClass: [] },
			{
				label: `Studies included in previous version of review (n=xxx)
Reports of studies included in previous version of review (n=xxx)`, addClass: ["arrow-down"]
			},
			{
				label: `Records identified from*:
Databases (n=xxx)
Registers (n=xxx)`, addClass: ["arrow-right", "arrow-down"]
			},
			{
				label: `Records removed before screening:
Duplicate records removed  (n=xxx)
Records marked as ineligible by automation tools (n=xxx)
Records removed for other reasons (n=xxx)`, addClass: []
			},
			{
				label: `Records identified from:
Websites (n=xxx)
Organisations (n=xxx)
Citation searching (n=xxx) etc.`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] }],
			[{ label: "Screening", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Records screened (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Records excluded** (n=xxx)", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] },
			{ label: "Reports sought for retrieval (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{ label: "Reports not retrieved (n=xxx)", addClass: [] }],
			[{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-down"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.`, addClass: []
			},
			{ label: "Reports assessed for eligibility (n=xxx)", addClass: ["arrow-right", "arrow-sub-left"] },
			{
				label: `Reports excluded:
Reason 1 (n=xxx)
Reason 2 (n=xxx)
Reason 3 (n=xxx) etc.`, addClass: []
			}],
			[{ label: "Included", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `New studies included in review (n=xxx)
Reports of new included studies (n=xxx)`, addClass: ["arrow-down"]
			},
			{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }],
			[{ label: "Included", addClass: [] },
			{ label: "", addClass: [] },
			{
				label: `Total studies included in review (n=xxx)
Reports of total included studies (n=xxx)`, addClass: []
			},
			{ label: "", addClass: [] },
			{ label: "", addClass: [] },
			{ label: "", addClass: [] }]
		]
	},

};

export { defaultProjectState };
