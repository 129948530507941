<template>
    <div v-if="showHome" class="page">
        <h1 style="margin-top: 10px;color: #327bb1;">Create a Prisma Diagram</h1>
        <div style="margin-top: 10px;">
            <div class="col-md-12 main">
                <div class="col-md-5 description">
                    <div class="box" :class="{ 'selected': selectedOption === index }"
                        v-for="(row, index) in $tera.state.prisma.prismaOptions" :key="index">
                        <label style="cursor: pointer;" :for="index">
                            <h3 style="margin-left: 10px;"> <input type="radio" v-model="selectedOption" :value="index"
                                    :id="index">
                                {{ row.title }}</h3>
                            <p style="margin-left: 24px;">{{ row.description }}</p>
                            <p style="margin-left: 24px; font-style: italic;color: #327bb1;">{{ row.officialTitle }}</p>
                            <a style="margin-left: 24px;" :href="row.link" target="_blank">{{ row.specification }}</a>
                        </label>
                    </div>
                </div>
                <div class="col-md-7 diagram">
                    <div class="imagebox" v-if="selectedOption == 0">
                        <!-- <img src="../assets/prisma_dr.png" style="width:80%" alt="Prisma Diagram 1"> -->
                        <img src="../assets/fig1.png" style="width:65%" alt="Prisma Diagram 1">
                    </div>
                    <div class="imagebox" v-else-if="selectedOption == 1">
                        <!-- <img src="../assets/prisma_dro.png" class="image" alt="Prisma Diagram 2"> -->
                        <img src="../assets/fig2.png" class="image" alt="Prisma Diagram 2">
                    </div>
                    <div class="imagebox" v-else-if="selectedOption == 2">
                        <!-- <img src="../assets/prisma_up_dr.png" style="width: 85%;" alt="Prisma Diagram 3"> -->
                        <img src="../assets/fig3.png" style="width: 85%;" alt="Prisma Diagram 3">
                    </div>
                    <div class="imagebox" v-else-if="selectedOption == 3">
                        <!-- <img src="../assets/prisma_up_dro.png" class="image" alt="Prisma Diagram 4"> -->
                        <img src="../assets/fig4.png" class="image" alt="Prisma Diagram 4">
                    </div>
                    <div class="imagebox" v-else>
                        <img src="../assets/TERA_PRISMA_Latest.svg" class="image" alt="Prisma Diagram Default">
                    </div>
                </div>
            </div>
            <div class="buttonsty">
                <input :disabled="selectedOption === null" class="btn btn-primary" type="button" value="Create Diagram"
                    @click="createDiagram" />
            </div>
        </div>
    </div>
    <div class="col-md-12" style="padding: 10px" v-else>
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div style="display: flex;width: -webkit-fill-available;">
                <div>
                    <p style="margin: 10px">Prisma Diagram</p>
                </div>
                <div style="float: right; align-self: center; margin-left: 80%;">
                    <input class="btn btn-primary" type="button" value="Replace Diagram.."
                        data-bs-toggle="modal" data-bs-target="#clearModal" />
                </div>
            </div>
        </nav>
        <div class="col-md-12 showDiagram ">
            <div v-if="selectedOption === 0" class="">
                <Diagram1 />
            </div>
            <div v-else-if="selectedOption === 1">
                <Diagram2 />
            </div>
            <div v-else-if="selectedOption === 2">
                <Diagram3 />
            </div>
            <div v-else>
                <Diagram4 />
            </div>
        </div>
        <!-- <div class="buttonsty">
            <input class="btn btn-secondary" type="button" value="Home" @click="showHome = true" />
        </div> -->
    </div>
    <div class="modal fade" id="clearModal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">Replace Diagram...</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure? This will wipe all existing diagram data
                </div>
                <div class="modal-footer">
                    <input class="btn btn-secondary" type="button" value="Cancel" data-bs-dismiss="modal" />
                    <input class="btn btn-primary" type="button" value="Ok" @click="confirmBack" data-bs-toggle="modal"
                        data-bs-target="#clearModal" />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Button from "primevue/button";
import Diagram1 from '@/components/PrismaDiagram1.vue'
import Diagram2 from '@/components/PrismaDiagram2.vue'
import Diagram3 from '@/components/PrismaDiagram3.vue'
import Diagram4 from '@/components/PrismaDiagram4.vue'
// import VImageMagnifier from 'v-image-magnifier'

export default {
    name: "PrismaHome",
    components: {
        // Button,
        Diagram1,
        Diagram2,
        Diagram3,
        Diagram4,
        // VImageMagnifier
    },
    data() {
        return {
            newId: "",
            shareUrl: "",
            prismaOptions: [{ title: 'Database and Register Search', description: "Prisma Diagram Specialised for search studies only", officialTitle: "Flow diagram for new systematic reviews which included searches of databases and registers only", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" },
            { title: 'Database, Registers and other Search', description: "Prisma Diagram Specialised for search studies from databases, Registers and other methods", officialTitle: "Flow diagram for new systematic reviews which included searches of databases, registers and other sources", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" },
            { title: 'Updated Database and Register Search', description: "Updated Prisma Diagram Specialised for search studies only", officialTitle: "Flow diagram for updated systematic reviews which included searches of databases and registers only", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" },
            { title: 'Updated Database, Registers and other Search', description: "Updated Prisma Diagram Specialised for search studies from databases, Registers and other methods", officialTitle: "Flow diagram for updated systematic reviews which included searches of databases, registers and other sources", specification: "Prisma 2020 Specification", link: "http://www.prisma-statement.org/PRISMAStatement/FlowDiagram" }],
            selectedOption: null,
            showHome: true,
            diagramType:0
        };
    },
    computed: {
    },
    methods: {
        createDiagram() {
            console.log("Creating new project");

            this.showHome = false;
        },
        confirmBack() {
            this.showHome = true
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.page {
    padding: 10px;
}

.main {
    display: flex;
    height: 78vh;
}

.description {
    border: solid 1px #51679d;
    margin: 2px;
    overflow-y: auto;
    border-radius: 17px;
}

.diagram {
    border: solid 1px #51679d;
    margin: 2px;
    border-radius: 17px;
    display: flex;
    align-items: center;
}

.buttonsty {
    float: right;
    margin-top: 10px;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    margin: 10px;
}

::-webkit-scrollbar-thumb {
    /* background: #536fab;  */
    background: #7f8daf;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #536fab;
}

.box {
    margin: 5px;
    border: solid 2px #ddd;
    padding: 15px;
    border-radius: 17px;
}

.box.selected {
    /* border-color: #51679d; */
    border: solid 4px #51679d;
}

.imagebox {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.image {
    /* width: 80vh;
    height: 70vh; */
    width: 90%;
}

.btn-style {
    border-radius: 20px;
}

.showDiagram {
    border: solid 1px #51679d;
    margin: 2px;
    overflow-y: auto;
    border-radius: 17px;
    height: 88vh;
    padding: 10px
}

/* td.arrow-down::after {
    top: 122px;
    right: 80px;
} */

/* td.arrow-right::before {
    top: 50px;=
} */

td.arrow-sub-left::before {
    top: 88px;
}

.custom-table td {
    width: 170px;
}

.custom-table th {
    text-align: center !important;
}

.navbar {
    /* background-image: linear-gradient(#6880b5, #465b8b, #5971a5); */
    color: #dcdee5;
    /* border-radius: 10px; */
    padding: 10px;
}

.navbar-dark {
    background-color: #343a40 !important;
    font-weight: 600;
    font-size: 18px;
}

.navbar-brand {
    color: #dcdee5;
}
</style>
