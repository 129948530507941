<template>
    <CustomTable :data="$tera.state.prisma.prismatableUpdateOtherSearch" :formateddata="$tera.state.prisma.formatedUpdateOtherSearch" :isTable="styleTable" :showAdd="show"
        @update-cell="changed($event)" @add-column="addColumn($event)" @add-row="addRow($event)"
        @delete-row="deleteRow($event)" @delete-column="deleteColumn($event)" />
</template>


<script>
import CustomTable from 'iebh-custom-table/src/components/CustomTable';
export default {
    components: {
        CustomTable
    },
    data() {
        return {
            show: false,
            styleTable: false
        };
    },
    methods: {
        changed(event) {
            console.log("Data Changed", event);
            this.$tera.state.prisma.prismatableUpdateOtherSearch.rows[event.rowIndex][event.cellIndex].label = event.updatedCell.label;
            const regex = /\(n=([^)]+)\)/g;
            let formattedText = event.updatedCell.label;
            formattedText = formattedText.replace(regex, (match, value) => {
                if (value === 'xxx') {
                    return `<span style="color: red;">${match}</span>`; // Apply red color for 'xxx'
                } else {
                    return `<span style="color: green;">${match}</span>`; // Apply green color for other values
                }
            });
            // console.log("format", this.$tera.state.prisma.formatedUpdateOtherSearch, formattedText)
            this.$tera.state.prisma.formatedUpdateOtherSearch.rows[event.rowIndex][event.cellIndex].label = formattedText;
        },
        addColumn(event) {
            console.log("Col added", event);
            this.$tera.state.prisma.prismatableUpdateOtherSearch.headers.splice(event.index + 2, 0, event.columnName);
            this.$tera.state.prisma.prismatableUpdateOtherSearch.rows.forEach(row => row.splice(event.index + 2, 0, { label: ' ', addClass: [] }));
            this.$tera.state.prisma.formatedUpdateOtherSearch.headers.splice(event.index + 2, 0, event.columnName);
            this.$tera.state.prisma.formatedUpdateOtherSearch.rows.forEach(row => row.splice(event.index + 2, 0, { label: ' ', addClass: [] }));
        },
        addRow(event) {
            console.log("Row add", event);
            const newRow = [];
            const numColumns = this.$tera.state.prisma.prismatableUpdateOtherSearch.rows[0].length;
            for (let i = 0; i < numColumns; i++) {
                newRow.push({ label: ' ', addClass: [] });
            }
            this.$tera.state.prisma.prismatableUpdateOtherSearch.rows.splice(event.index + 1, 0, newRow);
            this.$tera.state.prisma.formatedUpdateOtherSearch.rows.splice(event.index + 1, 0, newRow);
        },
        deleteRow(event) {
            console.log("Delete Row", event);
            this.$tera.state.prisma.prismatableUpdateOtherSearch.rows.splice(event, 1);
            this.$tera.state.prisma.formatedUpdateOtherSearch.rows.splice(event, 1);
        },
        deleteColumn(event) {
            console.log("Delete Col", event);
            this.$tera.state.prisma.prismatableUpdateOtherSearch.headers.splice(event, 1);
            this.$tera.state.prisma.prismatableUpdateOtherSearch.rows.forEach(row => { row.addClass = []; row.splice(event, 1) });
            this.$tera.state.prisma.formatedUpdateOtherSearch.headers.splice(event, 1);
            this.$tera.state.prisma.formatedUpdateOtherSearch.rows.forEach(row => { row.addClass = []; row.splice(event, 1) });
        }
    }
};
</script>

<style scoped></style>