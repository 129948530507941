<template>
  <!-- <PrismaTable /> -->
  <PrismaHome />
</template>

<script>
// import PrismaTable from './components/PrismaTable.vue'
import PrismaHome from './components/PrismaHome.vue'
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "primevue/resources/primevue.min.css";
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.js";
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
  name: 'App',
  components: {
    // PrismaTable
    PrismaHome
  }
}
</script>

<style scoped>

</style>
